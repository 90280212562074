<template>
    <div class="container d-flex">
        <div class="card m-auto">
            <div class="card-body">
                <h2>{{$t('verification_text')}}</h2>
                <p class="text-muted my-3">For verification, we ask you to enter the four digits of the zipcode.</p>

                <form v-on:submit.prevent="verifyZipcode">
                    <div class="form-group my-4">
                        <label class="text-black" for="">Enter zipcode digits*</label>
                        <input type="text" class="form-control" v-model="zipcode">
                        <div class="text-danger mt-1" v-if="error">{{error}}</div>
                    </div>

                    <button class="btn btn-primary btn-block" :disabled="submitting">
                        View assigning overview
                        <b-spinner v-if="submitting" small label="Spinning" variant="white"></b-spinner>
                    </button>
                </form>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            searchQuery: "",
            file: null,
            error: '',
            submitting: false,
            zipcode: ''
        };
    },
    created() {
    },
    computed: {
    },

    methods: {
        verifyZipcode() {
            if (!this.zipcode) {
                return;
            }
            this.submitting = true;
            this.error = '';
            let listing_id = this.$route.params.listing_id;
            let zipcode = this.zipcode;
            window.axios.post('/listings/verify/zipcode', { listing_id, zipcode }).then((response) => {
                let { data } = response;
                this.submitting = false;
                localStorage.setItem('accessToken', data.access_token);
                window.location.href = `/landlord/listing/${listing_id}/assignings`;
            }).catch(({ message, response }) => {
                this.error = response?.data?.message ?? message;
                this.submitting = false;
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.card {
    max-width: 400px;
}
</style>